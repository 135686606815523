import React from 'react';
import { graphql } from 'gatsby';
import Catapult from 'views/Catapult';

const IndexPage = ({ data: { posts } }): JSX.Element => {
  return <Catapult posts={posts?.nodes || []} />;
};

export default IndexPage;

export const query = graphql`
  {
    posts: allPost {
      nodes {
        name
        postPath: gatsbyPath(filePath: "/posts/{Post.name}")
        description
      }
    }
  }
`;
